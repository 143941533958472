@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  background-color: #0b0b0f;
  color: #fff;
  font-family: "Inter", sans-serif;
}

a {
  cursor: pointer;
}

input {
  outline: none;
}

::placeholder {
  color: #565976;
}

img {
  width: 100%;
}

.App {
  overflow: hidden;
}

.bg-gradient {
  background: linear-gradient(225deg, #18c8ff 14.89%, #933ffe 85.85%);
}

@media (min-width: 1380px) {
  .xl\:text-7xl {
    font-size: 5rem;
  }
}

h1,
.ca {
  word-wrap: break-word;
}

.bg-header {
  background-image: url("./assets/img/circles.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.text-gradient {
  background: linear-gradient(
    93deg,
    #00ffe4 10.79%,
    #a06ef4 50.87%,
    #e92ff6 85.51%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
